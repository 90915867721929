export enum Stack {
  unity,
  firebase,
  photoshop,
  illustrator,
  csharp,
  blender,
  ts,
  js,
  react,
  aws,
  redux,
  node,
}

type TStackAsset = { [key in Stack]: string };

export const StackAssets: TStackAsset = {
  [Stack.ts]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Fts-logo.png?alt=media&token=e0fe962b-956b-4612-9746-676e8798a0dc',
  [Stack.js]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Fjs-logo.png?alt=media&token=557f5a69-0aeb-487c-8b61-44e70ba45289',
  [Stack.react]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Freact-logo.png?alt=media&token=ef8f2d23-b005-400b-8677-d7b1165023da',
  [Stack.aws]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Faws-logo1.png?alt=media&token=9aa0ec54-be22-437c-add7-a0eb184793a9',
  [Stack.firebase]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Ffirebase-logo.png?alt=media&token=8e099681-593c-45b5-a115-51b1c8c0c3e4',
  [Stack.redux]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Fredux-logo.png?alt=media&token=c6c182b4-661b-4b3f-a4b4-1ed0ffce0418',
  [Stack.node]: 'https://firebasestorage.googleapis.com/v0/b/portfolio-8fa71.appspot.com/o/stack%2Fnodejs-logo.png?alt=media&token=f8728d3d-ca94-4e31-addf-44a45cc9cfaa',
  [Stack.unity]: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FUtils%2Funity.png?alt=media&token=8b8f25a8-4982-49ee-a330-9211282226b2',
  [Stack.photoshop]: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FUtils%2Fphotoshop.png?alt=media&token=584bc453-aa8b-4367-a13e-0cc1c142ac30',
  [Stack.illustrator]: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FUtils%2Fillustrator.png?alt=media&token=6f9a09d3-9156-43e7-a552-3858a5387d3e',
  [Stack.csharp]: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FUtils%2Fcsharp.png?alt=media&token=885f2683-1b82-411c-9fb8-4576e98fa606',
  [Stack.blender]: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FUtils%2Fblender.png?alt=media&token=2baf9ff7-a341-460c-91ba-91612f3482f3'
}

export type Deployment = {
  web?: string;
  android?: string;
  ios?: string;
};

export interface ISubProject {
  title: string;
  description: string;
  repository: string | null;
  deployment: Deployment,
}

export interface IProject {
  id: number;
  title: string;
  website: string | null;
  isOpenSource: boolean;
  banner: string;
  description: string;
  repository: string | null;
  stack: Stack[];
  screenshots: string[];
  isScreenshotsHorizontal: boolean;
  deployment: {
    web?: string;
    android?: string;
    ios?: string;
  },
  subProjects: ISubProject[]
}

export type Link = {
  type: string;
  title: string;
  url: string;
}

export interface IBody {
  title: string;
  description: string;
  link?: Link[]
}

const projects: IProject[] = [
  {
    id: 0,
    title: 'Party Games',
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FParty%20Games%2FParty%20Games%20Screen%201024x500.png?alt=media&token=3618f4e1-f805-47ad-b5c8-dc2f0fc2be85',
    website: null,
    isOpenSource: false,
    description: 'Have fun with duel your friends in a variety of 4 player minigames using simple one touch controls! Play simultaneously on the same device for multiplayer action on the road, wherever you are in offline game mode. Or invite your friends to a multiplayer game and play all mini-games online from anywhere in the world.',
    repository: null,
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FParty%20Games%2FEN-Bomber.png?alt=media&token=7cb7aaff-f0ce-4fdc-9427-734b6d07490b',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FParty%20Games%2FEN-Drive.png?alt=media&token=35c72f12-24ab-44fe-802b-0e90994defc7',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FParty%20Games%2FEN-Zuma.png?alt=media&token=55b65298-d033-4802-b2dd-15990bd3f9d0',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FParty%20Games%2FEN-Football.png?alt=media&token=5f852141-454c-4870-9e57-fcf8085a4534',
    ],
    isScreenshotsHorizontal: true,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.MalyaWka.PartyGamesPALS',
      ios: 'https://apps.apple.com/us/app/party-games-2-3-4-player-game/id1461746108',
    },
    subProjects: [],
  },
  {
    id: 1,
    title: 'Candy Match 3: Sweet Lands',
    website: null,
    isOpenSource: false,
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FCandy%20Match%2F1024x500.png?alt=media&token=5d6c2b99-c759-4a7a-8a1e-26e4e810a92f',
    description: 'New tasty and addictive match 3 puzzle Candy Match 3: Sweet Lands! Crush candies, collect bonuses and complete levels! Have fun!',
    repository: null,
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FCandy%20Match%2FCandyLandScreens_2.png?alt=media&token=d61ff549-2ea6-4959-b6bb-204c1dcaa1b0',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FCandy%20Match%2FCandyLandScreens_3.png?alt=media&token=794418e4-5f26-4f11-a5eb-0e95ab9095c9',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FCandy%20Match%2FCandyLandScreens_4.png?alt=media&token=be224dd8-0f1e-4524-95d3-87c20320f63d',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FCandy%20Match%2FCandyLandScreens_5.png?alt=media&token=ddbeea79-5a83-4947-abd7-4d508e181ba7',
    ],
    isScreenshotsHorizontal: false,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.PALS.CandyLand.Match3.CrushGame',
      ios: 'https://apps.apple.com/us/app/candy-match-3-sweet-lands/id1460563620',
    },
    subProjects: [],
  },
  {
    id: 2,
    title: 'WORD Stack',
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Stack%2F1025x500new.png?alt=media&token=14465ea3-a8f9-4935-9774-4254cece76d6',
    website: null,
    isOpenSource: false,
    description: 'This is perfect word stack puzzle or word search crossword for true geniuses! Want to boost your word game? Play word scramble game, stack words and exercise your brain with the BRAND NEW, incredibly addicting word game!',
    repository: null,
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Stack%2FEn-All.png?alt=media&token=89c9b940-4c58-42ea-9ff4-4f39fe8a37a4',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Stack%2FEn-Description.png?alt=media&token=b439df75-4e9f-4244-820e-02e83e8a568d',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Stack%2FEn-Es.png?alt=media&token=a22c8e9f-7b3b-43e1-bc02-8e0b13fd7d04',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Stack%2FEn-Ru.png?alt=media&token=c9500cdb-580a-44c5-b888-330a48d72922',
    ],
    isScreenshotsHorizontal: false,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.MalyaWka.Word.Quiz.Stuck',
      ios: 'https://apps.apple.com/us/app/word-stack-search-puzzle-game/id1460257186',
    },
    subProjects: [],
  },
  {
    id: 3,
    title: 'WORD Crush',
    website: null,
    isOpenSource: false,
    repository: null,
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Crush%2FWOW%201024x500%20EN.png?alt=media&token=6b82de45-f24e-428d-a361-df3a57a65444',
    description: 'Would you like to relax, exercise your brain, and expand your vocabulary-all at the same time? With Word & Crush, the not brand new, but incredibly addicting word game, you can!',
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Crush%2F1.png?alt=media&token=3f13f411-ad13-4de2-8c37-e1a6e275b697',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Crush%2F2.png?alt=media&token=3037558a-2ccc-48ce-ad38-33429fb3f098',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Crush%2F3.png?alt=media&token=159c2320-8e85-4aff-b028-3ddb4d001f21',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FWord%20Crush%2F4.png?alt=media&token=486c32a4-d400-48e9-b672-d28cebf1bcc2',
    ],
    isScreenshotsHorizontal: false,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.MalyaWka.WordAndCrush.Stacks',
      ios: 'https://apps.apple.com/us/app/word-crush/id1527122858',
    },
    subProjects: []
  },
  {
    id: 4,
    title: 'Surprise Eggs',
    isOpenSource: false,
    website: null,
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSurprise%20Eggs%2F1024x500.png?alt=media&token=af410921-1672-4a8d-a21e-c771cd707119',
    description: 'The Surprise Eggs catcher machine is great for children and adults alike. Collect the cutest, most adorable toy creatures from surprise eggs as you try to fill your collection.',
    repository: null,
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSurprise%20Eggs%2F20180508_121941.png?alt=media&token=1ce6f906-4356-4ded-889b-86d841b30eec',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSurprise%20Eggs%2F20180508_122126.png?alt=media&token=d4669df1-8a3a-483a-9c5f-d8df61bf4209',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSurprise%20Eggs%2F20180508_122255.png?alt=media&token=2f7a335f-ce41-4371-b0b8-571904ec6f9f',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSurprise%20Eggs%2F20180508_122336.png?alt=media&token=bf26ebca-17b7-4477-9754-58f0e436e130',
    ],
    isScreenshotsHorizontal: false,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.PALSDevStudio.SECM',
    },
    subProjects: []
  },
  {
    id: 5,
    title: 'Water Sort Puzzle: Halloween',
    banner: 'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSortEm%2FSort-Em-1024x500.png?alt=media&token=700807b0-2176-4918-bd24-be944363dc61',
    description: 'Enjoy a fun liquid sorting puzzle! Experience the Halloween atmosphere in Water Sort Puzzle: Halloween! Sort colorful water, fill test tubes, and enjoy the game anytime. Just tap to pour and solve vibrant puzzles!',
    isOpenSource: false,
    repository: null,
    stack: [Stack.unity, Stack.csharp, Stack.illustrator, Stack.photoshop],
    screenshots: [
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSortEm%2FScreen%201.png?alt=media&token=22af4a19-5e5b-42cb-b019-1e354c77da75',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSortEm%2FScreen%202.png?alt=media&token=a3a6c302-54b0-4541-b31a-0f5fd5c1bc5b',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSortEm%2FScreen%203.png?alt=media&token=bb658d16-1da5-4d88-8222-aa90771284a1',
      'https://firebasestorage.googleapis.com/v0/b/malyawka-dev.appspot.com/o/Images%2FGames%2FSortEm%2FScreen%204.png?alt=media&token=8991ec5c-0217-4c60-95e0-d22edc0db5d5',
    ],
    isScreenshotsHorizontal: false,
    deployment: {
      android: 'https://play.google.com/store/apps/details?id=com.MalyaWka.SortEmAll.Halloween',
      ios: 'https://apps.apple.com/us/app/water-sort-puzzle-halloween/id6470453444',
    },
    website: 'https://kcards-server.herokuapp.com',
    subProjects: []
  },
];

export default projects;