import projects, { IBody, IProject } from './projects';
import contact, { Contact, ContactType, LinkType } from './contact';

interface IConfig {
  name: string;
  fullName: string;
  intro: string;
  about: string;
  resume: string;
  contact: Contact;
  availableForHire: boolean;
  renderArticles: boolean;
  devToUsername: string;
  projects: IProject[];
  privacy: IBody[];
  terms: IBody[];
}

const Config: IConfig = {
  name: 'MalyaWka',
  fullName: 'Pavel MalyaWka Pustovalov',
  intro: 'A indie developer who loves make games and values learning and growing with people, teams, and technologies.',
  about: 'I am Pavel Malyawka Pustovalov, an indie developer passionate about creating games and constantly evolving. Since 2017, I have worked as a software engineer and 2D/3D artist, combining technical expertise with artistic skills to bring unique projects to life. My love for both programming and design enables me to create not just games, but immersive and visually appealing products that captivate and engage players.\n' +
  '\n' +
  'My professional journey began with the development of small applications and games, giving me valuable experience in working in a dynamic and complex environment. I specialize in developing high-quality software that meets modern standards, while adhering to budget and timeline constraints. Every game or app I create is the result of deep immersion in the process, a creative approach, and a constant desire to improve.\n' +
  '\n' +
  'Beyond development, I am also an artist and illustrator. My artistic vision and ability to translate ideas into visual form allow me to craft unique game worlds and characters that offer unforgettable experiences to players. I believe that creativity is just as important as technical proficiency, and I enjoy collaborating with others, exchanging ideas, and moving towards shared goals.\n' +
  '\n' +
  'I am always eager to learn and grow, as the gaming industry is rapidly evolving and demands flexibility and readiness for new challenges. I am open to exciting projects and partnerships where I can apply my skills, share my experience, and learn from other professionals.\n' +
  '\n' +
  'In my work, I value attention to detail, innovation, and the pursuit of excellence. Every project is an opportunity to grow as both a professional and an artist, and to make the gaming world more interesting and beautiful.',
  resume: 'https://drive.google.com/',
  renderArticles: false,
  availableForHire: false,
  devToUsername: 'malyawka',
  contact,
  projects,
  privacy: [
    {
      title: 'Privacy Policy',
      description:
      'MalyaWka (Alfeshka, PALS Dev Studio) built all apps as free apps. This SERVICE is provided by MalyaWka (Alfeshka, PALS Dev Studio) at no cost and is intended for use as is.\n' +
      'This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decides to use my Service.\n' +
      'If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.\n' +
      'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible in the apps unless otherwise defined in this Privacy Policy.',
    },
    {
      title: 'Information Collection and Use',
      description:
      'For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to Email, Device ID, and Advertising ID. The information that I request will be retained on your device and will not be collected by me in any way unless stated explicitly.\n' +
      'The apps use third-party services that may collect information used to identify you. These third parties have their own privacy policies, which should be reviewed by you.',
      link: [
        {
          type: LinkType.url,
          title: 'Google Play Services',
          url: 'policies.google.com/privacy'
        },
        {
          type: LinkType.url,
          title: 'AdMob',
          url: 'support.google.com/admob/answer/6128543?hl=en'
        },
        {
          type: LinkType.url,
          title: 'Google Analytics for Firebase',
          url: 'firebase.google.com/policies/analytics'
        },
        {
          type: LinkType.url,
          title: 'Firebase Crashlytics',
          url: 'firebase.google.com/support/privacy/'
        },
        {
          type: LinkType.url,
          title: 'Facebook',
          url: 'www.facebook.com/about/privacy/update/printable'
        },
        {
          type: LinkType.url,
          title: 'Unity',
          url: 'unity3d.com/legal/privacy-policy'
        },
        {
          type: LinkType.url,
          title: 'Yandex Ads',
          url: 'yandex.com/legal/international_ads_privacy_policy/'
        },
      ]
    },
    {
      title: 'Log Data',
      description:
      'Whenever you use my Service, in case of an error in the app, I collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device’s Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.'
    },
    {
      title: 'Cookies',
      description:
      'Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory.\n' +
      'This Service does not use these “cookies” explicitly. However, the apps may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, some portions of this Service may not function properly.'
    },
    {
      title: 'Service Providers',
      description:
      'I may employ third-party companies and individuals for the following reasons:\n' +
      ' • To facilitate our Service;\n' +
      ' • To provide the Service on our behalf;\n' +
      ' • To perform Service-related services;\n' +
      ' • To assist us in analyzing how our Service is used.\n' +
      'These third parties may have access to your Personal Information for the purpose of performing the tasks assigned to them. However, they are obligated not to disclose or use the information for any other purpose.'
    },
    {
      title: 'Security',
      description:
      'I value your trust in providing us your Personal Information and am committed to using commercially acceptable means to protect it. However, no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.'
    },
    {
      title: 'Links to Other Sites',
      description:
      'This Service may contain links to external sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me, and I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. I strongly advise you to review the Privacy Policy of these websites.'
    },
    {
      title: 'Children’s Privacy',
      description:
      'These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I can take necessary actions.'
    },
    {
      title: 'Changes to This Privacy Policy',
      description:
      'I may update our Privacy Policy from time to time. You are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.\n' +
      'This policy is effective as of 2024-10-19.'
    },
    {
      title: 'Contact Us',
      description:
      'If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at:',
      link: [
        {
          type: LinkType.email,
          title: 'malyawka@gmail.com',
          url: 'mailto:malyawka@gmail.com'
        },
        {
          type: LinkType.email,
          title: 'alfeshka@gmail.com',
          url: 'mailto:alfeshka@gmail.com'
        },
        {
          type: LinkType.email,
          title: 'devstudio.pals@gmail.com',
          url: 'mailto:devstudio.pals@gmail.com'
        }
      ]
    }
  ],  
  terms: [
    {
      title: 'Terms & Conditions',
      description: 
      'By downloading or using any apps, you automatically agree to these terms. Please make sure you read them carefully before using the apps.\n' +
      'You are not allowed to copy, modify, or attempt to extract the source code of the apps. The apps themselves, along with all trademarks, copyrights, and other intellectual property rights, remain the property of MalyaWka (Alfeshka, PALS Dev Studio).\n' +
      'I reserve the right to modify the apps or charge for their services at any time. However, I will always make it clear if any charges are to be applied.\n' +
      'All personal data that you provide is stored and processed in order to provide the Service. Please ensure that your device is secure. Do not root or jailbreak your device, as this could affect the security and functionality of the apps.\n' +
      'The apps use third-party services, each of which has its own Terms and Conditions, which you should review.',
      link: [
        {
          type: LinkType.url,
          title: 'Google Play Services',
          url: 'policies.google.com/terms',
        },
        {
          type: LinkType.url,
          title: 'AdMob',
          url: 'developers.google.com/admob/terms',
        },
        {
          type: LinkType.url,
          title: 'Google Analytics for Firebase',
          url: 'firebase.google.com/terms/analytics',
        },
        {
          type: LinkType.url,
          title: 'Firebase Crashlytics',
          url: 'firebase.google.com/terms/crashlytics',
        },
        {
          type: LinkType.url,
          title: 'Facebook',
          url: 'www.facebook.com/legal/terms/plain_text_terms',
        },
        {
          type: LinkType.url,
          title: 'Unity',
          url: 'unity3d.com/legal/terms-of-service',
        },
        {
          type: LinkType.url,
          title: 'Yandex Ads',
          url: 'yandex.com/legal/international_ads_privacy_policy/'
        },
      ]
    },
    {
      title: 'Changes to These Terms and Conditions',
      description: 'I may update these Terms and Conditions from time to time. You are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Terms and Conditions on this page.\n' +
      'These terms and conditions are effective as of 2024-10-19.',
    },
    {
      title: 'Contact Us',
      description: 'If you have any questions or suggestions about these Terms and Conditions, do not hesitate to contact me at:',
      link: [
        {
          type: LinkType.email,
          title: 'malyawka@gmail.com',
          url: 'mailto:malyawka@gmail.com',
        },
        {
          type: LinkType.email,
          title: 'alfeshka@gmail.com',
          url: 'mailto:alfeshka@gmail.com',
        },
        {
          type: LinkType.email,
          title: 'devstudio.pals@gmail.com',
          url: 'mailto:devstudio.pals@gmail.com',
        },
      ]
    }
  ]  
};

export * from './projects';
export * from './contact';
export default Config;