import React from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from 'react-native';
import { Heading } from '../../components';
import Config, { IBody, Stack, Link , ContactType, LinkType } from '../../config';
import { useTheme } from '../../store';
import { Colors, defaultContainerStyles, Theme, typography } from '../../theme';
import { enumToArray, openInNewTab } from '../../utils';

function Privacy(): React.ReactElement {
    const theme = useTheme(state => state.theme);
    const stack: Stack[] = enumToArray<Stack>(Stack);
    const privacy = Config.privacy;
  
    return (
      <View style={styles(theme).container}>
        <Heading label='Privacy Policy'/>
        <ScrollView style={styles(theme).container}>
            {privacy.map(subBody => <SubBody subBody={subBody} />)}
        </ScrollView>
      </View>
    );
  }

  interface SubBodyrops {
    subBody: IBody
  }

  interface LinkProps {
    type: string;
    title: string;
    url: string;
  }

  function SubBody(props: SubBodyrops) {
    const theme = useTheme(state => state.theme);
    const { subBody } = props;
    const { title, description, link } = subBody;
  
    return (
      <View style={styles(theme).subBody}>
        <Heading label={title} variant='small' />
        <Text style={[styles(theme).description, { marginBottom: 10 }]}>{description}</Text>
        {link?.map(linkProps => <SubLink type={linkProps.type} title={linkProps.title} url={linkProps.url} />)}
      </View>
    );
  }

  function SubLink(props: LinkProps) {
    if (props == null){
        return null;
    }

    const theme = useTheme(state => state.theme);
    const { type, title, url } = props;

    const onClick = (): void => {
        let contactUrl: string = url;
    
        if (type === LinkType.email) {
          contactUrl = `mailto:${contactUrl}`;
        } else {
          contactUrl = `https://${contactUrl}`;
        }
        openInNewTab(contactUrl);
      };
  
    return (
        <TouchableOpacity style={styles(theme).contactCard} onPress={onClick}>
        <Text style={styles(theme).contactCardText}>
          {title}
        </Text>
      </TouchableOpacity>
    );
  }

  const styles = (theme: Theme) => StyleSheet.create({
    container: {
      ...defaultContainerStyles(theme)
    },
    privacy: {
      fontSize: typography.body,
      color: Colors.white,
      fontWeight: '300',
      marginBottom: 20
    },
    description: {
        color: Colors.white,
        fontSize: typography.body,
        fontWeight: '300',
        marginBottom: 20,
      },
    subBody: {
    marginBottom: 15
    },
    contactText: {
        fontSize: typography.body,
        color: Colors.white,
        fontWeight: '300',
        marginBottom: 20
      },
      contactCard: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5,
      },
      contactCardText: {
        fontSize: typography.body,
        color: Colors.blue,
        marginLeft: 10,
        fontWeight: '300',
        textDecorationLine: 'underline'
      }
  });
  
  export default Privacy;