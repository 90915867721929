import { useNavigation } from '@react-navigation/native';
import { ScreenType } from '../navigation';

import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { responsiveHeight } from 'react-native-responsive-dimensions';
import { useTheme } from '../store';
import { typography, Theme, Colors } from '../theme';

function Footer() {
  const theme = useTheme(state => state.theme);
  const year = new Date().getFullYear();
  
  const navigation = useNavigation();

  const onPrivacy = () => {
    navigation.navigate(ScreenType.Privacy);
  };

  const onTermsUse = () => {
    navigation.navigate(ScreenType.Terms);
  };

  return (
    <View style={styles(theme).container}>
      <Text style={styles(theme).footer}>{year} © MalyaWka | <Text onPress={onPrivacy}>Privacy</Text> | <Text onPress={onTermsUse}>Terms of Use</Text> | All rights reserved</Text>
    </View>
  )
}

const styles = (theme: Theme) => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: responsiveHeight(5),
    height: responsiveHeight(12),
    backgroundColor: theme.footer
  },
  footer: {
    fontSize: typography.caption,
    color: theme.text,
    fontWeight: '300'
  }
});

export default Footer;