import React from 'react';
import AppNavigator from './src/navigation';

function App(): React.ReactElement {
  return (
      <AppNavigator />
  );
}

export default App;
